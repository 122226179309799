.mainConditionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  
    .mainCondition {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    }
  }